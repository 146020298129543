@import 'styles/index.sass';

.container {
	min-height: calc(100vh - 198px);
	padding: 40px 160px 42px;
	background: url('/assets/images/bg_1.png') no-repeat top 100px left -500px,
	url('/assets/images/bg_2.png') no-repeat top 200px right -625px {}

	@include mobile {
		padding: 32px 24px 42px;
		background: none; }

	.content {
		width: 100%;
		max-width: 760px;
		margin: 30px auto 0;
		border: 1px solid $c-border;
		border-radius: 10px; } }

