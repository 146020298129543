@import 'styles/index.sass';

.container {
	min-height: calc(100vh - 198px);
	padding: 40px 160px 42px;
	background: url('/assets/images/bg_1.png') no-repeat top 100px left -500px,
	url('/assets/images/bg_2.png') no-repeat top 200px right -625px {}

	@include mobile {
		padding: 32px 24px 42px;
		background: none; }

	.content {
		margin-top: 30px;

		@include laptop {
			width: fit-content; }

		@include mobile {
			display: flex;
			flex-direction: column;
			align-items: center;
			margin-top: 16px; }

		div {
			&:not(:last-child) {
				margin-bottom: 12px; }

			@include mobile {
				text-align: center; }

			span {
				display: block;
				margin-bottom: 2px; }

			a {
				&:hover {
					color: $c-primary; } } } } }

