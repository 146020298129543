@charset "utf-8";
* {
	box-sizing: border-box; }

body,div,
dl,dt,dd,
h1,h2,h3,h4,h5,h6,
pre,code,
form,fieldset,legend,input,button,textarea,
p,blockquote,
th,td {
	margin: 0;
	padding: 0; }

table {
	border-collapse: collapse;
	border-spacing: 0; }

fieldset,img {
	border: 0; }

addres,caption,cite,code,dfh,em,strong,th,var,optgroup {
	font-style: normal;
	font-weight: normal; }

del,ins {
	text-decoration: none; }

caption,th {
	text-align: left; }

h1,h2,h3,h4,h5,h6 {
	font-size: 100%;
	font-weight: normal; }

b,strong {
	font-weight: 600; }

q:before,q:after {
	content: ' '; }

abbr,acronym {
	border: 0;
	font-variant: normal; }

a {
	color: inherit;
	text-decoration: none;
	outline-style: none; }

button,a {
	cursor: pointer; }

textarea,input,button,a {
	font: inherit;
	background: none;
	outline: none;
	border: none;
	-webkit-appearance: none; }

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0; }


input[type=number] {
	-moz-appearance: textfield; }

input:-webkit-autofill {
	-webkit-box-shadow: 0 0 0px 1000px white inset; }

input, textarea {
	&:invalid {
		box-shadow: none; } }
