@import 'styles/index.sass';

.wrapper {
	display: flex;
	flex-direction: column;
	height: 100%;
	min-height: 100vh;

	.content {
		flex: 1 0 auto;
		background: url('/assets/images/bg_1.png') no-repeat top 64px left 0,
		url('/assets/images/bg_2.png') no-repeat bottom 32px right 0,
		url('/assets/images/bg_3.png') no-repeat bottom -92px left 30%,
		url('/assets/images/bg_4.png') no-repeat bottom 40px left calc(30% + 200px) {}

		@include mobile {
			background: url('/assets/images/bg_1_mobile.png') no-repeat top 0 left 0,
			url('/assets/images/bg_2_mobile.png') no-repeat top 0 right 0 {} } }

	.footer {
		flex: 0 0 auto;

		@include mobile {
			flex-direction: column;
			align-items: flex-start; } } }

