@import 'styles/index.sass';

.comments {
	@include laptop {
		padding: 40px 60px;
		border: 1px solid $c-border;
		border-radius: 10px; }

	.textarea_wrapper {
		display: flex;

		@include laptop {
			align-items: flex-end;
			margin-top: 16px;
			padding: 9px 24px 9px 11px;
			border: 1px solid $c-border;
			border-radius: 10px; }

		@include mobile {
			flex-direction: column;
			align-items: flex-end; }

		.textarea {
			width: 100%;
			margin: 16px 10px 16px 0;
			resize: none;

			@include mobile {
				min-height: 100px;
				margin: 16px 0 8px;
				padding: 6px;
				border: 1px solid $c-border;
				border-radius: 10px; }

			&__button {
				margin: 10px 0;
				font-weight: normal; } } }

	&__list {
		max-height: 500px;
		margin-top: 24px;
		overflow: auto;

		@include mobile {
			max-height: 250px; }

		.item {
			display: flex;
			align-items: flex-start;

			&:not(:last-child) {
				margin-bottom: 24px; }

			.avatar {
				width: 56px;
				height: 56px;
				object-fit: cover;
				border-radius: 6px;
				background: #f9f7f6;

				@include mobile {
					width: 40px;
					height: 40px; } }

			.content {
				margin-left: 16px;
				font-size: 16px;
				line-height: 20px;

				&__text {
					font-weight: 400; }

				&__like {
					display: flex;
					align-items: baseline;
					margin-top: 10px;
					color: $c-gray;
					font-weight: 400;

					button {
						&:disabled {
							cursor: default; } }

					span {
						margin-right: 4px; } } } } } }


