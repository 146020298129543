@import 'styles/index.sass';

.container {
	width: 100%;
	max-width: 1000px;
	min-height: calc(100vh - 198px);
	padding: 40px 0;
	margin: 0 auto;

	@include mobile {
		padding: 32px 24px 42px; }

	.button {
		position: fixed;
		top: 140px;
		font-weight: normal;

		&_back {
			left: 32px;

			&__icon {
				margin-right: 13px;
				transform: rotate(180deg); } }

		&_save {
			right: 32px;
			min-width: 117px; } }

	.status {
		@include laptop {
			position: fixed;
			top: 140px;
			right: 30px; }

		@include mobile {
			margin-bottom: 20px; } }

	h2 {
		text-align: center; }

	.content {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin: 24px 0 32px;

		.block {
			width: 100%;
			background: white;

			&:not(:first-child) {
				margin-top: 30px; }

			@include laptop {
				width: 100%;
				padding: 24px 32px;
				margin-top: 10px;
				margin-top: 10px;
				background: white;
				border: 1px solid $c-border;
				border-radius: 10px; } }

		.homework {
			width: 100%;
			margin-top: 30px; } } }
