@import 'styles/index.sass';

.container {
	width: 100%;
	max-width: 1000px;
	min-height: calc(100vh - 198px);
	padding: 40px 0;
	margin: 0 auto;

	@include mobile {
		padding: 32px 16px 42px; }

	.button {
		position: fixed;
		top: 140px;
		font-weight: normal;

		&_back {
			left: 32px;

			&__icon {
				margin-right: 13px;
				transform: rotate(180deg); } } }

	.status {
		@include laptop {
			position: fixed;
			top: 140px;
			right: 30px; }

		@include mobile {
			margin-bottom: 20px; } } }

.block {
	width: 100%;
	background: white;

	&:not(:first-child) {
		margin-top: 30px; }

	@include laptop {
		width: 100%;
		padding: 24px 32px;
		margin-top: 10px;
		margin-top: 10px;
		background: white;
		border: 1px solid $c-border;
		border-radius: 10px; }

	&__caption {
		display: flex;
		justify-content: space-between;
		align-items: center;

		&__score {
			font-weight: 600; } }

	&__text {
		margin: 24px 0 11px; }

	&__answer {
		margin: 5px 0 16px; }

	&__option {
		display: flex;
		align-items: center;

		&:not(:last-child) {
			margin-bottom: 9px; } }

	.score {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		margin-top: 20px;

		&__value {
			width: 100px;
			margin-left: 10px; } } }

.controls {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin-top: 30px;

	&__button {
		&:first-child {
			width: 246px;
			margin-right: 10px; }

		&:last-child {
			width: 119px; } } }
