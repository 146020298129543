@import 'styles/index.sass';

.field {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin-top: 20px;

	&_score {
		width: 100px;
		margin-left: 12px; } }

.controls {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin-top: 20px;

	.button {
		font-weight: normal;

		&:first-child {
			margin-right: 10px; } } }

.task {
	&__caption {
		margin-bottom: 11px; }

	&__text {
		margin-bottom: 24px; }

	&__controls {
		display: flex;
		justify-content: flex-end;
		margin-top: 8px;

		&__button {
			font-weight: normal; } } }

