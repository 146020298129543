@import 'styles/index.sass';

.container {
	.content {
		display: flex;
		justify-content: space-between;
		align-items: center;

		.controls {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			width: 100%;

			.button {
				font-weight: normal;

				&:first-child {
					margin-right: 10px; } } } } }

.image {
	width: 100%;
	height: 500px;
	object-fit: cover;

	@include mobile {
		height: 250px; } }

