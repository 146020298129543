@import './colors';

@mixin mobile {
	@media (max-width: 768px) {
		@content; } }

@mixin laptop {
	@media (min-width: 769px) {
		@content; } }

@mixin link {
	color: $c-pink; }

@mixin link-item {
	&:after {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		content: ''; } }
