@import 'styles/index.sass';

.menu {
	position: relative;
	margin-bottom: 24px;

	.popup {
		position: absolute;
		top: 50px;
		right: 0;
		width: 100%;
		min-width: 210px;
		padding: 16px;
		background: white;
		box-shadow: 0px 1px 3px rgba(black, 0.25);
		border-radius: 16px;
		z-index: 2;

		button {
			display: block;

			&:not(:last-child) {
				margin-bottom: 6px; } } } }
