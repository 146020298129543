@import 'styles/index.sass';

.check {
	position: relative;
	display: flex;
	align-items: center;
	cursor: pointer;

	&:hover {
		color: black; }

	&__input {
		position: absolute;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;

		&:checked + .check__box {
			border: none;
			background-color: $c-primary;

			&:after {
				display: flex;
				align-items: center;
				width: 14px;
				height: 14px;
				content: url('/assets/images/check.svg');
				margin-top: 2px; }

			&:hover {
				transition: 0.3s;
				background: $c-blue; } }

		&:focus + .check__box {
			border-color: $c-primary; } }

	&__box {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 20px;
		min-width: 20px;
		height: 20px !important;
		border-radius: 20px;
		border: 1px solid $c-primary;
		margin-right: 15px;
		transition: 0.3s;

		&_disabled {
			border: 1px solid transparent !important;
			background: #eaebee; }

		&:hover {
			border: 1px solid $c-blue; } } }
