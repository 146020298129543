@import 'styles/index.sass';

.content {
	width: 100%;

	.cover {
		width: 100%;
		background: url('/assets/images/background.png') no-repeat bottom;
		background-size: cover;

		@include mobile {
			background: url('/assets/images/mobile-background.png')  no-repeat bottom;
			background-size: cover; }

		&__content {
			max-width: 780px;
			margin: 0 auto;
			padding: 50px 0 74px;

			h2 {
				color: black; }

			@include mobile {
				padding: 36px 40px 72px; }

			.wrapper {
				@include laptop {
					display: flex;
					align-items: center; }

				@include mobile {
					margin-bottom: 16px; }

				.link {
					display: flex;
					align-items: center;
					margin-right: 8px;
					color: black;

					@include mobile {
						margin-bottom: 8px; }

					&:hover {
						color: $c-blue; }

					span {
						margin-right: 8px; } } } } }

	.info {
		display: flex;
		justify-content: space-between;
		align-items: center;
		max-width: 780px;
		min-height: 100px;
		margin: -50px auto 0;
		padding: 24px 56px;
		border-radius: 90px;
		background: white;
		box-shadow: 0px 10px 34px rgba(black, 0.25);

		@include mobile {
			flex-direction: column;
			align-items: flex-end;
			margin: -50px 8px 0;
			padding: 24px 32px;
			border-radius: 10px;
			box-shadow: 0px 10px 34px rgba(black, 0.15); }

		&__item {
			display: flex;

			@include mobile {
				justify-content: space-between;
				width: 100%; }

			div {
				&:first-child {
					margin-right: 56px;

					@include mobile {
						margin-right: 20px; } }

				&:last-child {
					@include mobile {
						text-align: right; } } }

			.caption {
				margin-bottom: 4px;
				color: $c-gray;
				font-size: 16px;
				line-height: 19px;
				font-weight: 400; } } } }
