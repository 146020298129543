@import 'styles/index.sass';

.block {
	&__text {
		margin: 24px 0 11px; }

	&__file {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 7px 16px;
		background: #d6dddf;
		border-radius: 4px;
		font-weight: 700;
		margin-top: 8px;

		&__name {
			opacity: 0.2; }

		&__button {
			padding: 0;
			font-weight: normal;
			height: fit-content; } }

	&__button {
		margin-top: 20px;
		font-weight: normal; }

	&__comment {
		margin-top: 20px;

		&__text {
			margin-top: 10px; } } }

