@import 'styles/index.sass';

.menu {
	top: 0;
	right: 0;
	width: 300px;
	background: white;
	text-align: center;
	padding: 40px 16px;

	&__button {
		display: none; }

	@include mobile {
		width: 100% !important; }

	&__overlay {
		top: 0;
		left: 0; }

	.notification {
		display: block;
		border: 1px solid $c-gray;
		padding: 8px 16px;
		border-radius: 4px;

		&_not_read {
			border-color: $c-blue; }

		span {
			display: block;
			margin-bottom: 4px;
			color: $c-gray;
			font-weight: normal; }

		b {
			text-align: left; } }

	li {
		margin: 0;

		&:not(:last-child) {
			margin-bottom: 20px; } } }

.body {
	overflow: hidden; }

