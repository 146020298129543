@import 'styles/index.sass';

.container {
	min-height: calc(100vh - 198px);
	padding: 40px 160px 42px;
	background: url('/assets/images/bg_1.png') no-repeat top 100px left -500px,
	url('/assets/images/bg_2.png') no-repeat top 200px right -625px {}

	@include mobile {
		padding: 32px 24px 42px;
		background: none; }

	.content {
		display: flex;
		justify-content: center;
		align-items: flex-start;
		margin-top: 24px;

		@include mobile {
			flex-direction: column;
			align-items: center; }

		.avatar {
			@include laptop {
				margin-right: 40px; }

			@include mobile {
				width: 100%; }

			&__content {
				@include mobile {
					display: flex;
					align-items: flex-start;
					margin-top: 16px; } }

			.photo {
				margin: 48px 0 16px;

				img {
					width: 200px;
					height: 200px;
					border-radius: 10px;
					object-fit: cover;

					@include mobile {
						width: 100px;
						height: 100px; } }

				@include mobile {
					margin: 0 12px 24px 0; }

				.plug {
					width: 200px;
					height: 200px;
					border-radius: 10px;
					background: #f9f7f6;

					@include mobile {
						width: 100px;
						height: 100px; } } } }

		.form {
			@include laptop {
				width: 320px;
				margin-top: 24px; }

			@include mobile {
				margin-top: 16px; }

			&__field {
				margin-bottom: 16px; }

			&__error {
				margin-top: 24px;
				color: $c-pink; }

			.button {
				margin: 24px 0 12px; } } } }

.modal {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border: 1px solid #ccc;
	background: white;
	overflow: auto;
	border-radius: 10px;
	outline: none;
	padding: 20px;

	&__controls {
		display: flex;
		justify-content: flex-end; } }

.overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(black, 0.45);
	z-index: 1; }

