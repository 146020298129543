@import 'styles/index.sass';

.container {
	padding: 30px 20px;

	@include laptop {
		padding: 40px 160px; }

	.controls {
		display: flex;
		justify-content: flex-end; }

	.answers {
		margin-top: 40px;

		@include mobile {
			margin-top: 25px; }

		&_empty {
			text-align: center; }

		&__item {
			position: relative;
			max-width: 900px;
			margin: 0 auto;
			padding: 20px 16px;
			border-radius: 12px;
			border: 1px solid #d0d0d0;

			@include laptop {
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 25px 20px; }

			&:hover {
				transition: 0.3s;
				box-shadow: 0px 4px 11px rgba(black, 0.15); }

			&:not(:last-child) {
				margin-bottom: 20px; }

			.link {
				@include link-item; }

			.score {
				position: absolute;
				top: 10px;
				right: 4px;
				display: inline-block;
				margin-right: 16px;
				text-align: center; }

			.status {
				@include mobile {
					margin-top: 16px; } }

			.info {
				@include laptop {
					width: 70%; }

				h4 {
					&:first-child {
						margin-bottom: 8px;

						@include mobile {
							margin-top: 12px; } } } } } }

	.load_button {
		margin: 20px auto 0; } }
