@import 'styles/index.sass';

.container {
	display: flex;
	justify-content: space-between;
	align-items: center;

	.controls {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		width: 100%; }

	.file {
		display: flex;
		align-items: center;

		h4 {
			margin-left: 16px;
			word-break: break-word; } }

	.button {
		font-weight: normal;

		&:first-child {
			margin-right: 10px; }

		&_download {
			width: 100px; } } }
