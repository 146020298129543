@import 'styles/index.sass';

.modules {
	max-width: 780px;
	margin: 40px auto 64px;

	@include mobile {
		margin: 24px auto 40px; }

	&__item {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 16px 56px;
		border-bottom: 1px solid #ded1d1;

		@include mobile {
			padding: 10px 16px; }

		&_disabled {
			pointer-events: none;
			border-bottom: 1px solid rgba(#ded1d1, 0.4); }

		.caption {
			margin-right: 15px;

			&_disabled {
				opacity: 0.3; }

			.lessons {
				font-size: 16px;
				line-height: 19px;
				font-weight: 400;
				color: $c-gray;
				margin-bottom: 4px; } }

		.button {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 40px;
			height: 40px;
			border-radius: 50%;
			background: #eee8e8;
			color: #808080; } } }

