@import 'styles/index.sass';

.card {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	max-width: 352px;
	background: #eef6f8;
	border: 1px solid #dcf5fc;
	border-radius: 12px;
	padding: 32px 24px;

	&_active {
		&:hover {
			transition: 0.3s;
			box-shadow: 0px 10px 34px rgba(black, 0.25); } }

	.link {
		@include link-item; }

	.tariff {
		position: absolute;
		right: 24px;
		top: -18px;
		padding: 8px 16px;
		border-radius: 4px;
		color: white;
		line-height: 18px; }

	.description {
		margin-top: 16px;
		padding-bottom: 24px;
		border-bottom: 1px solid $c-line;
		color: $c-gray; }

	.dates {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin: 24px 0 16px;

		&__caption {
			color: $c-gray;
			font-weight: 400; }

		&__value {
			margin-top: 1px; } }

	.message {
		margin-top: 24px;
		padding: 16px;
		border: 1px solid $c-line;
		border-radius: 4px;

		p {
			color: $c-gray;
			font-size: 16px;
			line-height: 19px;
			font-weight: 400; }

		&_finished {
			text-align: center; } }

	.progressbar_container {
		margin-bottom: 36px;

		.label {
			color: $c-gray;
			font-weight: 400;
			margin-bottom: 4px; }

		.progressbar {
			position: relative;
			height: 16px;
			background: #cdd6d8;
			border-radius: 4px; }

		.progressbar > span {
			position: relative;
			display: block;
			height: 100%;
			background-color: #574ae2;
			overflow: hidden;
			border-radius: 4px; } }

	.routing {
		display: flex;
		justify-content: space-between;
		align-items: center;
		color: $c-primary;

		h4 {
			margin-right: 10px; } } }

