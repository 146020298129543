@import 'styles/index.sass';

ul {
	@include laptop {
		display: flex;
		align-items: center; } }

li {
	@include laptop {
		margin-right: 30px; }

	@include mobile {
		margin-bottom: 30px; } }

ol, ul, li {
	padding: 0;
	border: 0;
	list-style: none;
	font-weight: 700;
	outline: none; }

.menu {
	top: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100% !important;
	background: white;
	padding: 24px 16px;
	text-align: center;

	&__button {
		display: none; }

	&__overlay {
		top: 0;
		left: 0; }

	&__list {
		display: flex;
		align-items: center; } }

.body {
	overflow: hidden; }

