@import 'styles/index.sass';

.content {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 24px;

	.block {
		width: 100%;
		padding: 24px 32px;
		margin-top: 10px;
		background: white;
		border: 1px solid $c-border;
		border-radius: 10px; } }
