@import 'styles/index.sass';

.container {
	padding: 40px 160px;
	background: url('/assets/images/bg_1.png') no-repeat top 100px left -500px,
	url('/assets/images/bg_2.png') no-repeat top 200px right -625px {}

	@include mobile {
		padding: 32px 16px;
		background: none; }

	.caption {
		margin-bottom: 32px;
		font-size: 32px;
		line-height: 38px;
		font-weight: 800; }

	.union {
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
		margin-top: 40px;
		padding: 32px 24px 0;
		border: 1px solid #d0d0d0;
		border-radius: 12px;

		@include mobile {
			justify-content: center; }

		.course {
			@include laptop {
				width: 300px;
				margin-right: 32px;
				margin-bottom: 32px; }

			@include mobile {
				width: 100%; }

			&__description, &__date_title {
				margin: 16px 0 24px;
				font-weight: 400;
				color: $c-gray; }

			&__date {
				margin-bottom: 8px; } } }

	.list {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -16px;

		@include mobile {
			justify-content: center;
			width: 100%; }

		&__item {
			margin: 0 16px 32px;

			@include mobile {
				margin: 0 0 32px; } } }

	.empty {
		display: flex;
		flex-direction: column;
		justify-content: center;
		height: calc(100vh - 278px);

		&__text {
			max-width: 575px;
			font-size: 32px;
			line-height: 38px;
			font-weight: 700;
			margin: auto;
			text-align: center; } } }

