@import 'styles/index.sass';

.card {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	width: 260px;
	border: 1px solid #d0d0d0;
	border-radius: 12px;
	padding: 24px 16px;

	&:hover {
		transition: 0.3s;
		box-shadow: 0px 10px 34px rgba(black, 0.25); }

	@include laptop {
		min-height: 400px; }

	@include mobile {
		width: 100%;
		min-width: 260px;
		min-height: 200px; }

	.link {
		@include link-item; }

	&__description {
		margin-top: 16px;
		font-size: 14px;
		line-height: 17px;
		font-weight: 400;
		color: $c-gray; } }
