@import 'styles/index.sass';

.block {
	position: relative;

	&_draggable {
		cursor: move; }

	&__control {
		position: absolute;
		top: 0;
		right: -50px;
		height: 34px;
		border: 1px solid $c-border;
		border-radius: 10px;

		.popup {
			position: absolute;
			top: 40px;
			right: 0;
			min-width: 210px;
			padding: 16px;
			background: white;
			box-shadow: 0px 1px 3px rgba(black, 0.18);
			border-radius: 16px;
			z-index: 1;
			cursor: default;

			button {
				display: block;

				&:not(:last-child) {
					margin-bottom: 10px; } } } } }

