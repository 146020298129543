@import 'styles/index.sass';

.container {
	display: flex;
	align-items: center;

	.progressbar {
		stroke-dasharray: 75 /* (2PI * 24px) */; }

	.percent {
		margin-left: 6px; } }
