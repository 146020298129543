@import 'styles/index.sass';

.controls {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin-top: 20px;

	.button {
		font-weight: normal;

		&:first-child {
			margin-right: 10px; } } }

