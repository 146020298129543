@import 'colors';
@import 'mixins';

@font-face {
	font-family: 'Circe';
	src: local('Circe Text Light'), local('CirceText-Light'),
	url('/assets/fonts/circe/Circe-Light.woff') format('woff'),
	url('/assets/fonts/circe/Circe-Light.ttf') format('ttf') {}
	font-weight: 300;
	font-style: normal;
	font-display: swap; }

@font-face {
	font-family: 'Circe';
	src: local('Circe Text Bold'), local('CirceText-Bold'),
	url('/assets/fonts/circe/Circe-Bold.woff') format('woff'),
	url('/assets/fonts/circe/Circe-Bold.ttf') format('ttf') {}
	font-weight: 300;
	font-style: normal;
	font-display: swap; }

@font-face {
	font-family: 'Circe';
	src: local('Circe Text Regular'), local('CirceText-Regular'),
	url('/assets/fonts/circe/Circe-Regular.woff') format('woff'),
	url('/assets/fonts/circe/Circe-Regular.ttf') format('ttf') {}
	font-weight: 300;
	font-style: normal;
	font-display: swap; }

body {
	font-size: 18px;
	line-height: 23px;
	font-family: Circe;
	color: $c-base; }

h1 {
	font-size: 48px;
	line-height: 48px;
	font-weight: bold;

	@include mobile {
		font-size: 24px;
		line-height: 29px; } }

h2 {
	font-size: 32px;
	line-height: 38px;
	font-weight: 700;

	@include mobile {
		font-size: 32px;
		line-height: 32px; } }

h3 {
	font-size: 24px;
	line-height: 24px;
	font-weight: 700;

	@include mobile {
		font-size: 18px;
		line-height: 22px; } }

h4 {
	font-size: 20px;
	line-height: 24px;
	font-weight: 700;

	@include mobile {
		font-size: 18px;
		line-height: 22px; } }

h5 {
	font-size: 16px;
	line-height: 20px;
	font-weight: 700; }
