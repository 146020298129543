@import 'styles/index.sass';

.form {
	@include laptop {
		width: 320px;
		margin-top: 24px; }

	@include mobile {
		margin-top: 16px; }

	&__field {
		margin-bottom: 16px; }

	.button {
		margin: 24px 0 12px; } }

