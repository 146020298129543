@import 'styles/index.sass';

.container {
	width: 100%;
	max-width: 1000px;
	min-height: calc(100vh - 198px);
	padding: 40px 0;
	margin: 0 auto;

	@include mobile {
		padding: 32px 16px 42px; }

	.button {
		position: fixed;
		top: 140px;
		font-weight: normal;

		&_back {
			left: 32px;

			&__icon {
				margin-right: 13px;
				transform: rotate(180deg); } }

		&_save {
			right: 32px;
			min-width: 117px; } }

	h2 {
		text-align: center; } }
