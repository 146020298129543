@import 'styles/index.sass';

.block {
	width: 100%;
	background: white;

	&:not(:first-child) {
		margin-top: 30px; }

	@include laptop {
		width: 100%;
		padding: 24px 32px;
		margin-top: 10px;
		margin-top: 10px;
		background: white;
		border: 1px solid $c-border;
		border-radius: 10px; }

	&__text {
		margin: 24px 0 11px; }

	&__answer {
		margin: 5px 0 16px;

		&__file {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 7px 16px;
			background: #d6dddf;
			border-radius: 4px;
			font-weight: 700;
			margin-bottom: 20px;

			&__button {
				padding: 0;
				font-weight: normal;
				height: fit-content; } } } }

.score {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin-top: 20px;

	&__value {
		width: 100px;
		margin-left: 10px; } }
