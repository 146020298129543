@import 'styles/index.sass';

.lessons {
	max-width: 780px;
	margin: 40px auto 64px;

	@include mobile {
		margin: 24px auto 40px; }

	&__item {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		padding: 16px 56px;
		border-bottom: 1px solid #ded1d1;

		@include mobile {
			padding: 10px 16px; }

		h3 {
			margin-top: 12px; }

		.button {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 40px;
			height: 40px;
			border-radius: 50%;
			background: #eee8e8;
			color: #808080; } } }

