@import 'styles/index.sass';

.container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 26px 30px;
	border-top: 1px solid #c4c4c4;

	@include mobile {
		padding: 32px 24px 40px; }

	.contacts {
		display: flex;
		align-items: center;

		@include mobile {
			flex-direction: column;
			align-items: flex-start; }

		&__company {
			font-weight: 700; }

		&__item {
			@include mobile {
				margin-bottom: 24px; } }

		&__info {
			display: block;
			font-weight: 400;
			opacity: 0.5;

			&:last-child {
				margin-top: 2px; } }

		&__tax {
			@include laptop {
				margin-left: 50px; }

			@include mobile {
				margin-bottom: 22px; } }

		&__links {
			display: flex;
			align-items: center;

			&__icon {
				color: #0067f2; } } } }

