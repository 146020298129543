@import 'styles/index.sass';

.block {
	width: 100%;
	background: white;

	&:not(:first-child) {
		margin-top: 30px; }

	@include laptop {
		width: 100%;
		padding: 24px 32px;
		margin-top: 10px;
		margin-top: 10px;
		background: white;
		border: 1px solid $c-border;
		border-radius: 10px; }

	&__caption {
		display: flex;
		justify-content: space-between;
		align-items: center;

		&__score {
			font-weight: 600; } }

	&__text {
		margin: 24px 0 11px; }

	&__option {
		display: flex;
		align-items: center;

		&:not(:last-child) {
			margin-bottom: 9px; } }

	&__controls {
		display: flex;
		justify-content: center;
		margin-top: 24px;

		button {
			width: 184px; } } }
