@import 'styles/index.sass';

.container {
	text-align: left; }

.input {
	display: flex;
	align-items: center;
	padding: 10px 13px;
	border: 1px solid $c-border;
	border-radius: 5px;
	background: white;

	&:focus-within, &:hover {
		transition: 0.5s;
		border-color: #565656; }

	&_disabled {
		background: #f9f7f6;

		&:focus-within, &:hover {
			border-color: $c-border; }

		input {
			color: #808080; } }

	input {
		font-size: 18px;
		line-height: 27px;
		font-weight: 400; }

	&__label {
		display: inline-block;
		margin-bottom: 2px;
		font-size: 16px;
		line-height: 19px;
		font-weight: 400; }

	&__clear {
		margin-left: 14px;
		color: #b7b7b6; }

	&__suffix {
		margin-left: 14px; } }

