:root {
	--ck-border-radius: 12px !important;
	--ck-color-base-text: #707070 !important;
	--ck-color-base-border: transparent !important;
	--ck-focus-ring: 1px solid transparent !important;
	--ck-color-shadow-inner: none !important;
	--ck-z-modal: 1 !important;
}

.ck-editor__editable {
	min-height: 150px;
}

.ck-editor__main {
	cursor: text;
}
