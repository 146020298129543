@import 'styles/index.sass';

.accordion {
	border-top: 1px solid $c-border;
	padding: 0 56px;
	display: flex;
	flex-direction: column;
	width: 100%;

	@include mobile {
		padding: 0 32px; }

	&:nth-of-type(1) {
		border-top-width: 0px; }

	button {
		padding: 32px 0 16px;
		text-align: left;
		color: $c-base;

		@include mobile {
			padding: 16px 0 8px; } }

	&__content {
		margin-bottom: 16px;
		overflow: hidden;
		max-height: 1000px;
		transition: max-height 1s ease-in-out;

		@include mobile {
			margin-bottom: 8px; } }

	&__content[aria-expanded='true'] {
		max-height: 0px;
		transition: max-height 0.5s cubic-bezier(0, 1, 0, 1); } }
