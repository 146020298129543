@import 'styles/index.sass';

.option {
	display: flex;
	align-items: flex-start;
	margin-top: 12px;

	&__score {
		width: 100px;
		margin-left: 12px;

		&_first {
			margin-left: 42px; } }

	&__control {
		margin-top: 12px;
		font-weight: normal;

		&_delete {
			margin-top: 14px;
			margin-left: 12px; } } }

.controls {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin-top: 20px;

	.button {
		font-weight: normal;

		&:first-child {
			margin-right: 10px; } } }

.task {
	&__caption {
		margin-bottom: 11px; }

	&__text {
		margin-bottom: 24px; }

	&__option {
		display: flex;
		align-items: center;

		&:not(:last-child) {
			margin-bottom: 9px; } } }

