@import 'styles/index.sass';

.container {
	padding: 40px 160px 0;
	background: url('/assets/images/bg_1.png') no-repeat top 100px left -500px,
	url('/assets/images/bg_2.png') no-repeat top 200px right -625px {}

	@include mobile {
		padding: 32px 24px 0;
		background: none; }

	.caption {
		margin-bottom: 32px;
		font-size: 32px;
		line-height: 38px;
		font-weight: 800; }

	.list {
		display: flex;
		flex-wrap: wrap;
		margin: 30px -16px 42px;

		&__item {
			margin: 22px 16px;

			@include mobile {
				margin: 18px 16px; } }

		@include mobile {
			margin: 10px -16px 22px; } }

	.empty {
		display: flex;
		flex-direction: column;
		justify-content: center;
		height: calc(100vh - 278px);

		&__text {
			max-width: 575px;
			font-size: 32px;
			line-height: 38px;
			font-weight: 700;
			margin: auto;
			text-align: center; } } }

