@import 'styles/index.sass';

.editor {
	width: 100%;
	border: 1px solid #D9D9D9;
	border-radius: 10px;

	&:hover {
		transition: 0.5s;
		border-color: #565656; }

	&_focus {
		border-color: #565656; }

	&_error {
		border: 1px solid red; }

	&_disabled {
		&:hover {
			border-color: #D9D9D9; } } }
